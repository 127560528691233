import { useCallback } from 'react';
import { checkUsernameAndPassword, resetPassword } from '@/services/user';
import { useRequest } from 'ahooks';
import { cryptPsw, cryptSaltPsw, getTimestamp, randomString } from '@/utils/utils';
import { useLogout } from '.';
import useUserInfo from './useUserInfo';

type ResetPassowrdHookResult = {
  reset: (params: resetType) => {};
  loading: boolean;
};

type resetType = {
  oldPassword: string;
  newPassword: string;
};

export default function useResetPassowrd(callback?: () => void): ResetPassowrdHookResult {
  const { logout } = useLogout();
  const { currentUser } = useUserInfo();
  const { run: runCheck, loading: checkLoading } = useRequest(checkUsernameAndPassword, {
    manual: true,
  });

  const { run: runReset, loading: resetLoading } = useRequest(resetPassword, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        logout();
        callback?.();
      }
    },
  });

  // 登录方法
  const reset = useCallback(
    async ({ oldPassword, newPassword }: resetType) => {
      const salt = randomString();
      // 对称盐值加密
      const oldPwd = cryptSaltPsw(salt, oldPassword);
      // MD5
      const newPwd = cryptPsw(newPassword);
      const res = await runCheck({
        userName: currentUser?.email as string,
        userPwd: newPwd,
        timestamp: getTimestamp(),
      });
      if (res.success) {
        runReset({
          salt,
          timestamp: getTimestamp(),
          oldUserPwd: oldPwd,
          newUserPwd: newPwd,
        });
      }
    },
    [currentUser],
  );

  return {
    reset,
    loading: checkLoading || resetLoading,
  };
}
