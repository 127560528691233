import { useRequest } from 'ahooks';
import { fetchProductConfigEnum } from '@/services/global';
import { getTimestamp } from '@/utils/utils';
import { useState } from 'react';

const useRegionMap = () => {
  const [map, setMap] = useState<Record<string, string>>({});
  useRequest(fetchProductConfigEnum, {
    defaultParams: [{ productConfigItemEnum: 'REGION', _: getTimestamp() }],
    onSuccess: (res) => {
      if (res.success) {
        const newMap: Record<string, string> = {};
        res.data.forEach((item: { text: string; value: string }) => {
          newMap[item.value] = item.text;
        });
        setMap(newMap);
      }
    },
  });
  return map;
};

export default useRegionMap;
