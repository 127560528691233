import type { Effect, Reducer } from 'umi';
import { fetchPlatform } from '@/services/global';

export type PlatformType = {
  createTime: number;
  deputyLogoUrl: string;
  domainId: number;
  domainName: string;
  emailBody: string;
  logoUrl: string;
  officialDomainName: string;
  platformPrincipalModel: { superUserId: number; email: string; fullName: string };
  principalContact: string;
  settlementRatio: string;
  signature: string;
  status: string;
  subjectAddress: string;
  subjectInfo: string;
  subjectPhone: string;
  subjectPostcode: string;
};

export interface GlobalModelState {
  platform?: Partial<PlatformType>; // 当前用户信息
  eyeClosed?: boolean;
  isMobile?: boolean;
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    fetchPlatform: Effect;
  };
  reducers: {
    savePlatform: Reducer<GlobalModelState>;
    saveEyeClosed: Reducer<GlobalModelState>;
    saveMobileStatus: Reducer<GlobalModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<GlobalModelState>;
  };
}

const GlobalModel: GlobalModelType = {
  namespace: 'global',
  state: {
    platform: {},
    eyeClosed: true,
    isMobile: false,
  },

  effects: {
    *fetchPlatform({ payload }, { call, put }) {
      const res = yield call(fetchPlatform, payload);
      if (res?.success) {
        yield put({
          type: 'savePlatform',
          payload: res?.data,
        });
      }
    },
  },
  reducers: {
    savePlatform(state, action) {
      return {
        ...state,
        platform: { ...action.payload },
      };
    },
    saveEyeClosed(state, action) {
      return {
        ...state,
        eyeClosed: action.payload,
      };
    },
    saveMobileStatus(state, action) {
      return {
        ...state,
        isMobile: action.payload,
      };
    },
  },
};

export default GlobalModel;
