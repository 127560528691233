import account from './account/fr-FR.json';
import affiliate from './affiliate/fr-FR.json';
import component from './component/fr-FR.json';
import menus from './menus/fr-FR.json';
import user from './users/fr-FR.json';
import global from './global/fr-FR.json';
import support from './support/fr-FR.json';
import result from './result/fr-FR.json';
import finance from './finance/fr-FR.json';
import region from './region/fr-FR.json';
import request from './request/fr-FR.json';

const titleMenu = Object.entries(menus).reduce((pre: object, [key, value]: [string, string]) => {
  // 添加统一后缀
  return {
    ...pre,
    [`title.${key}`]: `${value} - LightNode`,
  };
}, {});

export default {
  ...account,
  ...affiliate,
  ...component,
  ...menus,
  ...titleMenu,
  ...user,
  ...global,
  ...support,
  ...result,
  ...finance,
  ...region,
  ...request,
};
