import { checkIsLogin } from '@/services/user';
import { useRequest } from 'ahooks';
import { useState } from 'react';

export interface checkLoginHookResult {
  loading: boolean;
  isLogin: boolean;
  checked: boolean;
  refresh: () => void;
  check: (params?: any) => void;
}

function useCheckLogin(): checkLoginHookResult {
  const [checked, setChecked] = useState<boolean>(false);
  const { loading, data, refresh, run } = useRequest(checkIsLogin, {
    manual: true,
    onSuccess: () => {
      setChecked(true);
    },
    onError: () => {
      setChecked(true);
    },
  });

  return {
    check: run,
    loading,
    isLogin: !!data?.data,
    checked,
    refresh,
  };
}

export default useCheckLogin;
