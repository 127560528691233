import type { ConnectState } from '@/models/connect';
import { useSelector } from 'umi';

export function useMobile() {
  const { isMobile } = useSelector((state: ConnectState) => {
    return {
      isMobile: !!state.global.isMobile,
    };
  });
  return { isMobile: isMobile as boolean };
}
