import { history } from 'umi';
import imgMobileArrowLeft from '@/assets/mobile/mobile-arrow-left@2x.png';
import './index.less';
import { DotLoading } from 'antd-mobile';
import { useEventListener } from 'ahooks';
import type { CSSProperties } from 'react';
import { useState } from 'react';

interface MobileContainerProps {
  title: string | React.ReactNode;
  loading?: boolean;
  back?: boolean | string;
  className?: string;
  backFn?: () => void;
  footer?: React.ReactNode;
}

const MobileContainer: React.FC<MobileContainerProps> = ({
  title,
  children,
  back,
  className,
  backFn,
  loading,
  footer,
  ...props
}) => {
  const [vh, setVh] = useState(window.innerHeight * 0.01 + 'px');
  useEventListener('resize', () => {
    setVh(window.innerHeight * 0.01 + 'px');
  });
  return (
    <div
      {...props}
      style={{ opacity: loading ? 0.5 : '', '--vh': vh } as CSSProperties}
      className={`mobile-container ${className || ''}`}
    >
      <div className={'mobile-container-title'}>
        {back && (
          <div
            onClick={() => {
              if (backFn) {
                backFn();
              } else {
                history.goBack();
              }
            }}
          >
            <img src={imgMobileArrowLeft} className='mobile-container-title-back-icon' alt='back-icon' />
          </div>
        )}
        <span>{title}</span>
      </div>
      <div className={'mobile-container-content'}>{children}</div>
      {loading && (
        <div className='mobile-container-loading'>
          <span style={{ fontSize: 24 }}>
            <DotLoading />
          </span>
        </div>
      )}
      {footer && <div className='mobile-container-footer'>{footer}</div>}
    </div>
  );
};

export default MobileContainer;
