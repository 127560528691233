import { useCallback, useEffect, useRef } from 'react';
import { useAccountInfo } from './request';

export function useDev() {
  const devRef = useRef(false);
  // createScript
  const createScript = useCallback((src: string) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      if ((window as any).VConsole) {
        new (window as any).VConsole();
      }
    };
  }, []);
  const { currentAccount } = useAccountInfo();
  // 调用开发者工具
  const handleUseDevTool = useCallback((account: any = {}) => {
    if (account.email && devRef.current === false) {
      createScript('https://unpkg.com/vconsole@latest/dist/vconsole.min.js');
      devRef.current = true;
    }
  }, []);
  useEffect(() => {}, [currentAccount, handleUseDevTool]);
}
