import { useRequest } from 'ahooks';
import { fetchRegionAndZoneDetailInfo } from '@/services/global';
import type { BaseOptions } from '@ahooksjs/use-request/lib/types';
import { isArray } from 'lodash';

type ZoneType = {
  saleStatus: string;
  zoneCode: string;
  zoneName: string;
};
export type RegionType = {
  saleStatus: string;
  regionCode: string;
  regionName: string;
  nationalFlag: string;
  zones?: ZoneType[];
};

const useRegionAndZone = <R, P extends any[]>(options?: BaseOptions<R, P>) => {
  const result = useRequest<{ data?: RegionType[] }, P, (RegionType & Record<string, Record<string, string>>)[]>(
    fetchRegionAndZoneDetailInfo,
    {
      formatResult: (res) => {
        return isArray(res.data) ? res.data.map((item) => ({ ...item, ...JSON.parse(item.regionName) })) : [];
      },
      ...options,
    },
  );
  return result;
};

export default useRegionAndZone;
