import { Skeleton } from 'antd';
import PageContainer from '@/components/PageContainer';
import MobileContainer from '@/components/Mobile/MobileContainer';
import { Skeleton as MSkeleton } from 'antd-mobile';
import { useMemo } from 'react';

const Loading = () => {
  const isMobile = useMemo(() => {
    const flag = window.navigator.userAgent.match(
      // iPad pad 待定
      // Mobile ios 属性移除 移动端浏览器可以访问PC
      /(phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
    return flag;
  }, []);
  return isMobile ? (
    <MobileContainer title=''>
      <MSkeleton.Paragraph animated style={{ width: '100%' }} />
    </MobileContainer>
  ) : (
    <PageContainer title=''>
      <Skeleton />
    </PageContainer>
  );
};

export default Loading;
