import request from '@/utils/request';

// 获取月账单
export const fetchUserMonthBill = (params: any) => {
  return request.get('/api/finance/flowbillmgmt/getUserMonthBill.do', {
    params,
  });
};

// 总消耗金额
export const fetchPriceConsumption = (params: any) => {
  const { date, ...other } = params;
  return request.get(`/api/finance/flowbillmgmt/describeMonthConsumptionOverViewHistory/${date}`, {
    params: other,
  });
};

// 月消耗金额
export const fetchMonthPriceConsumption = (params: { billDate: string }) => {
  return request.get('/api/finance/flowbillmgmt/decribeMonthConsumptionOverView.do', { params });
};

// 获取pdf下载地址
export const fetchPdfHref = (params: { billDate: string; language: string }) => {
  return request.get('/api/finance/flowbillmgmt/exportMonthConsumptionBillList', { params });
};

// 获取记录页
export const fetchRecord = (params: any) => {
  return request.get('/api/order/prodorder/getOrdListPage.do', {
    params,
  });
};
