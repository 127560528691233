import account from './account/zh-CN.json';
import affiliate from './affiliate/zh-CN.json';
import component from './component/zh-CN.json';
import menus from './menus/zh-CN.json';
import user from './users/zh-CN.json';
import global from './global/zh-CN.json';
import support from './support/zh-CN.json';
import result from './result/zh-CN.json';
import finance from './finance/zh-CN.json';
import region from './region/zh-CN.json';
import request from './request/zh-CN.json';

const titleMenu = Object.entries(menus).reduce((pre: object, [key, value]: [string, string]) => {
  // 添加统一后缀
  return {
    ...pre,
    [`title.${key}`]: `${value} - LightNode`,
  };
}, {});

export default {
  ...account,
  ...affiliate,
  ...component,
  ...menus,
  ...titleMenu,
  ...user,
  ...global,
  ...support,
  ...result,
  ...finance,
  ...region,
  ...request,
};
