import type { ConnectState } from '@/models/connect';
import { fetchAccountInfo } from '@/services/user';
import { getTimestamp } from '@/utils/utils';
import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import type { AccountType } from '@/models/user';
import { useDispatch, useSelector } from 'umi';
import type { BaseOptions } from '@ahooksjs/use-request/lib/types';

interface useAccountInfoProps {
  isDispatch?: boolean;
}

export default function useAccountInfo<R, P extends any[]>(
  props: useAccountInfoProps = { isDispatch: false },
  requestProps?: BaseOptions<R, P>,
) {
  const dispatch = useDispatch();
  const { currentAccount, loading }: { currentAccount?: AccountType; loading: boolean } = useSelector(
    (state: ConnectState) => {
      return {
        currentAccount: state.user.account,
        loading: state.loading.effects['user/fetchAccountInfo'],
      };
    },
  );

  const { loading: requestLoading, ...requestParams } = useRequest<R, P>(fetchAccountInfo, {
    manual: true,
    ...requestProps,
  });

  const dispatchUser = () => {
    dispatch({
      type: 'user/fetchAccountInfo',
      payload: {
        _: getTimestamp(),
      },
    });
  };

  useEffect(() => {
    if (!props.isDispatch) return;
    dispatchUser();
  }, [props.isDispatch]);

  return {
    currentAccount,
    loading,
    requestParams,
    requestLoading,
    dispatch: dispatchUser,
    ...requestParams,
  };
}
