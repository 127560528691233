import { checkUserInfo } from '@/services/user';
import { useRequest } from 'ahooks';
import { useState } from 'react';
import type { BaseOptions } from '@ahooksjs/use-request/lib/types';
import { useHistory } from 'umi';
import { getTimestamp } from '@/utils/utils';

export interface checkLoginHookResult {
  loading: boolean;
  checked: boolean;
  run: (...args: any) => Promise<any>;
}

function useCheckUserInfo<R, P extends any[]>(
  options?: { notGo: boolean },
  requestProps?: BaseOptions<R, P>,
): checkLoginHookResult {
  const [checked, setChecked] = useState<boolean>(false);
  const history = useHistory();
  const { loading, run } = useRequest<R | any, P>((params) => checkUserInfo({ ...params, _: getTimestamp() }), {
    onSuccess: (res) => {
      // 不带跳转逻辑 默认带
      if (options?.notGo) {
        setChecked(true);
        return;
      }
      if (res.success) {
        history.push('/action/improve-info');
      } else if (history.location.pathname.indexOf('/action/improve-info') !== -1) {
        history.push('/product');
      }
      setChecked(true);
    },
    onError: () => {
      setChecked(true);
    },
    ...requestProps,
  });

  return {
    loading,
    checked,
    run,
  };
}

export default useCheckUserInfo;
