import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

// 获取当前时间戳
export const getTimestamp = () => window.parseInt(new Date().getTime() as any, 10);

/**
 * 随机数(盐)
 * */
export function randomString() {
  const len = 32;
  const char = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = char.length;
  let ranString = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    ranString += char.charAt(Math.floor(Math.random() * maxPos));
  }
  return ranString;
}

// 盐值加密
export function cryptSaltPsw(salt: string, pwd: string) {
  const md5_pwd = CryptoJS.algo.MD5.create();
  md5_pwd.update(pwd);
  const md5 = CryptoJS.algo.MD5.create();
  md5.update(salt);
  md5.update(md5_pwd.finalize());
  return md5.finalize().toString();
}
// MD5加密
export function cryptPsw(pwd: string) {
  const md5_pwd = CryptoJS.algo.MD5.create();
  md5_pwd.update(pwd);
  return md5_pwd.finalize().toString();
}

// 删除cookie
export function deleteCookie(name: string, path?: string, domain?: string) {
  if (domain) {
    Cookies.remove(name, { path, domain });
  } else if (path) {
    Cookies.remove(name, { path: '' });
  } else {
    Cookies.remove(name);
  }
}

// 跳转至父组件
export const masterHistory = {
  push: (url: string) => {
    window.history.pushState({}, '', url);
  },
  replace: (url: string) => {
    window.history.replaceState({}, '', url);
  },
};
/**
 * 格式化金额
 */

export function dollarFormat(_money: number | string) {
  const money = +_money;
  if (money === 0) return '$ 0.00';
  if (!money) return '--';
  if (money < 0) return '-$ '.concat(parseFloat(`${money * -1}`).toFixed(2));
  return '$ '.concat(parseFloat(`${money}`).toFixed(2));
}

// 加法
export function add(...val: any) {
  let max = 0;
  let count = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    const strVal = val[i].toString();
    const index = strVal.indexOf('.');
    let num = 0;
    if (index > -1) {
      num = strVal.length - 1 - index;
      max = num > max ? num : max;
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    count += Math.round(val[i] * Math.pow(10, max));
  }
  // eslint-disable-next-line no-restricted-properties
  return count / Math.pow(10, max);
}

// 减法
export function sub(...val: any) {
  let max = 0;
  let count = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    const strVal = val[i].toString();
    const index = strVal.indexOf('.');
    let num = 0;
    if (index > -1) {
      num = strVal.length - 1 - index;
      max = num > max ? num : max;
    }
  }
  // eslint-disable-next-line no-restricted-properties
  count = Math.round(val[0] * Math.pow(10, max));
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < val.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    count -= Math.round(val[i] * Math.pow(10, max));
  }
  // eslint-disable-next-line no-restricted-properties
  return count / Math.pow(10, max);
}

// 乘法
export function multiply(...val: any) {
  let max = 0;
  let count = 1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    const strVal = val[i].toString();
    const index = strVal.indexOf('.');
    let num = 0;
    if (index > -1) {
      num = strVal.length - 1 - index;
      max += num > max ? num : max;
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    count *= Number(val[i].toString().replace('.', ''));
  }
  // eslint-disable-next-line no-restricted-properties
  return count / Math.pow(10, max);
}

export function downLoadWithHref(href: string, filename?: string) {
  const a = document.createElement('a'); // 创建a标签
  a.href = href;
  a.download = filename || '';
  a.click(); // 自执行点击事件
}

/**
 *  下载按钮点击事件保存为blob
 * @param url // 连接
 * @param filename // 文件名
 * @param ext 扩展名
 */
export function downloadFileWithBlob(url: string, filename: string, ext: string) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status === 200) {
      const blob = this.response as Blob;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `${filename}.${ext}` || '';
      a.click();
    }
  };
  xhr.send();
}
// js图片文件压缩转文件
export function compressImg(file: File, quality: number = 0.8) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const { width, height } = img;
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);
        canvas.toBlob(resolve, file.type, quality);
      };
    };
  });
}
