import { Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { history } from 'umi';
import { useMobile } from '@/hooks';
import './index.less';

interface PageContainerProps {
  title: string | React.ReactNode;
  loading?: boolean;
  back?: boolean | string;
  background?: boolean | string; // 背景颜色
  shadow?: boolean; // 阴影
  className?: string;
  loadingCenter?: boolean;
  backFn?: () => void;
}

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  loading = false,
  children,
  back,
  background,
  shadow = true,
  className,
  loadingCenter = false,
  backFn,
  ...props
}) => {
  const { isMobile } = useMobile();
  return (
    <div {...props} className={`${[isMobile ? 'mobile-' : '', 'k-page-container'].join('')} ${className || ''}`}>
      <div className={'k-page-container-title'}>
        {back && (
          <LeftOutlined
            className='back-icon'
            onClick={() => {
              if (backFn) {
                backFn();
              } else {
                history.goBack();
              }
            }}
          />
        )}
        {title}
      </div>
      <Spin spinning={loading} size='large' wrapperClassName={loadingCenter ? 'container-spin' : ''}>
        <div
          style={{ backgroundColor: typeof background === 'string' ? background : '' }}
          className={`k-page-container-content ${background ? 'k-page-container-background' : ''} ${
            shadow ? 'shadow' : ''
          }`}
        >
          {children}
        </div>
      </Spin>
    </div>
  );
};

export default PageContainer;
