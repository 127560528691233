import type { ConnectState } from '@/models/connect';
import { fetchUserInfo } from '@/services/user';
import { getTimestamp } from '@/utils/utils';
import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';
import type { BaseOptions } from '@ahooksjs/use-request/lib/types';

interface useUserInfoProps {
  isDispatch?: boolean;
}

export default function useUserInfo<R, P extends any[]>(
  props: useUserInfoProps = { isDispatch: false },
  requestProps?: BaseOptions<R, P>,
) {
  const dispatch = useDispatch();
  const { currentUser, loading } = useSelector((state: ConnectState) => {
    return {
      currentUser: state.user.user,
      loading: state.loading.effects['user/fetchUserInfo'],
    };
  });

  const { loading: requestLoading, ...requestParams } = useRequest<R, P>(fetchUserInfo, {
    manual: true,
    ...requestProps,
  });

  const dispatchUser = () => {
    dispatch({
      type: 'user/fetchUserInfo',
      payload: {
        _: getTimestamp(),
      },
    });
  };

  useEffect(() => {
    if (!props.isDispatch) return;
    dispatchUser();
  }, [props.isDispatch]);

  return {
    currentUser,
    loading,
    requestParams,
    requestLoading,
    dispatch: dispatchUser,
    ...requestParams,
  };
}
