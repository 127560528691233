import { useState, useCallback } from 'react';
import { fetchRechargeById } from '@/services/global';
import { useRequest } from 'ahooks';
import moment from 'moment';

interface statusType {
  status?: string; //请求状态
  msg?: string; //错误信息
  // count?: number;  //请求次数
}

export default function useRecharge() {
  const [status, setStatus] = useState<any>({}); //正在请求的所有支付id{支付id: statusType}

  const handleCheckCharge = useCallback((map?: statusType) => {
    setStatus((m: statusType) => {
      return { ...m, ...map };
    });
  }, []);

  // 轮询充值请求
  const { run: handleCheck, fetches } = useRequest(fetchRechargeById, {
    manual: true,
    // pollingInterval: 3000,
    // pollingWhenHidden: false,
    fetchKey: (...params: any[]) => {
      return params[0];
    },
    onSuccess: (res: any, params: any) => {
      const id: string = params[0];
      const mapStatus: any = JSON.parse(JSON.stringify(status));
      // mapStatus[id].count += 1;
      if (res?.success) {
        if (res.status === 'SUCCESS') {
          mapStatus[id].status = 'rechargeSuccess';
          window?.fbq?.('track', 'Purchase', { value: res?.realAmount || 0, currency: 'USD' });
          if (res?.firstTimesRechargeGiveAwayPriorityAmount) {
            window?.fbq?.('track', 'Subscribe', {
              value: res?.realAmount || 0,
              currency: 'USD',
              predicted_ltv: res?.firstTimesRechargeGiveAwayPriorityAmount || 0,
            });
          }
        } else if (!moment().isBefore(moment(res?.timeoutDate)) && res.status === 'PAYING') {
          mapStatus[id].status = 'rechargeFail';
          mapStatus[id].msg = 'TIMEOUT';
        } else if (res.status === 'FAILURE') {
          mapStatus[id].status = 'rechargeFail';
          mapStatus[id].msg = res.resultMsg;
        } else if (moment().isBefore(moment(res?.timeoutDate))) {
          handleCheck(params[0]);
        }
      } else {
        mapStatus[id].status = '';
      }
      handleCheckCharge({ ...mapStatus });
      if (!res?.success || res?.status !== 'PAYING' || !moment().isBefore(moment(res?.timeoutDate))) {
        fetches[id]?.cancel();
      }
    },
  });

  const handleUpdateCount = (id: string) => {
    // 初始请求
    const mapStatus: any = JSON.parse(JSON.stringify(status));
    mapStatus[id] = {
      status: 'rechargePending',
      // count: mapStatus?.[id]?.count || 0,
      msg: '',
    };
    handleCheckCharge({ ...mapStatus });
    handleCheck(id);
  };

  // 初始化调用
  const fetch = useCallback((query: any) => {
    // 如果当前结果页在支付中，则轮询支付查询接口，并存储
    const ids = JSON.parse(sessionStorage.getItem('lightNodeRechargeIDs') || '[]') || [];
    if (query?.type === 'rechargePending' && query?.id) {
      handleUpdateCount(query?.id);
      if (
        ids.every((m: any) => {
          return m !== query?.id;
        })
      ) {
        ids.push(query.id);
        sessionStorage.setItem('lightNodeRechargeIDs', JSON.stringify(ids));
      }
    }
    // 不论是否在支付页都需要轮询缓存中的支付id
    for (const item in ids) {
      if (!(ids[item] in status) && ids[item] !== query?.id) {
        handleUpdateCount(ids[item]);
      }
    }
  }, []);

  return {
    status,
    fetch,
    handleCheckCharge,
  };
}
