import { useIntl } from 'umi';

type PrimitiveType = string | number | boolean | null | undefined | Date;

type optionsType = {
  id: string;
  defaultMessage?: string; // 默认名称
  dynamicMessage?: Record<string, PrimitiveType> | undefined;
};
export function useFomatter(): {
  formatter: (options: optionsType) => string;
} {
  const intl = useIntl();
  const formatter = (options: optionsType) => {
    return intl.formatMessage(
      {
        id: options.id,
        defaultMessage: options?.defaultMessage,
      },
      {
        ...options?.dynamicMessage,
      },
    );
  };
  return { formatter };
}
