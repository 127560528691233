import { useRequest } from 'ahooks';

import { checkCaptcha, getAppKey } from '@/services/global';
import { useState } from 'react';
import { getTimestamp } from '@/utils/utils';

declare const window: any;
type optionsType = {
  id: string;
  // 在初始化前调用
  init?: (args?: any) => void;
  // 校验完成后作用
  callback?: (args?: any) => void;
};

export interface AWSCHookResult {
  // 重置状态
  reload: (callback?: () => void) => void;
  // 初始化生成标签
  init: () => void;
  // 是否创建
  isCreate: boolean;
  // 验证状态
  isAuth: boolean;
}

const useAWSC = ({ init, id, callback }: optionsType): AWSCHookResult => {
  // 创建判断
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const { AWSC } = window;
  const removeChildren = () => {
    const elem = document.getElementById(id);
    while (elem?.hasChildNodes()) {
      // 当elem下还存在子节点时 循环继续
      if (elem?.firstChild) {
        elem?.removeChild(elem?.firstChild);
      }
    }
  };

  const { run: runCheckCaptcha, data, mutate } = useRequest(checkCaptcha, {
    manual: true,
    onSuccess: (res) => {
      if (res.result) {
        callback?.(res);
        removeChildren();
      }
    },
  });

  const { run: runGetAPi } = useRequest(getAppKey, {
    manual: true,
    onSuccess: (res) => {
      let ALI_appkey: any;
      let ALI_scene: any;
      if (res.appKey) {
        ALI_appkey = res.appKey;
        ALI_scene = res.scene;
      } else {
        return;
      }
      if (isCreate) return;
      init?.();
      setIsCreate(true);
      AWSC.use('ic', function (state: any, module: any) {
        // 初始化
        window.ic = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: ALI_appkey,
          // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: 'nvc_register',
          // 声明智能验证需要渲染的目标元素ID。
          renderTo: id,
          // 智能验证组件初始状态文案。
          default_txt: 'Click the button to start verification',
          // 智能验证组件验证通过状态文案。
          success_txt: 'Verified successfully',
          // 智能验证组件验证失败（拦截）状态文案。
          fail_txt: 'Verification failed, please click here to refresh',
          // 智能验证组件验证中状态文案。
          scaning_txt: 'Intelligent detection',
          language: 'en',
          width: '100%',
          upLang: {
            cn: {
              // 加载状态提示。
              LOADING: 'Loading...',
              // 等待滑动状态提示。
              SLIDE: 'Please swipe right to verify',
              // 验证通过状态提示。
              SUCCESS: 'Verified',
              // 验证失败触发拦截状态提示。
              ERROR: 'Sorry, there was a network error...',
              // 验证失败触发拦截状态提示。
              FAIL: 'Verification failed, please try again',
            },
          },
          // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success(_data: any) {
            if (_data) {
              const checkparams = {
                sessionId: _data.sessionId,
                sig: _data.sig,
                token: _data.token,
                scene: ALI_scene,
              };
              runCheckCaptcha(checkparams);
            }
          },
          // 验证失败时触发该回调参数
          fail(failCode: any) {
            console.log(failCode);
          },
          // 验证码加载异常时触发该回调参数
          error(errorCode: any) {
            console.log(errorCode);
          },
        });
      });
    },
  });

  const createAuthComponent = () => {
    if (isCreate) return;
    runGetAPi({ _: getTimestamp() });
  };

  const reload: AWSCHookResult['reload'] = (_callback: any) => {
    setIsCreate(false);
    mutate(undefined);
    removeChildren();
    _callback?.();
  };

  return {
    init: createAuthComponent,
    reload,
    isCreate,
    isAuth: data?.result,
  };
};

export { useAWSC };
