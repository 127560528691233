import type { Effect, Reducer } from 'umi';
import { fetchAccountInfo, fetchUserInfo } from '@/services/user';
import { history } from 'umi';

export type UserType = {
  accountType: number; // 用户类型
  city: string;
  zipCode: string;
  companyName: string;
  country: string;
  address: string;
  corpName: string;
  domainId: number;
  domainIds: number[];
  domainName: string;
  email: string;
  emailStatus: number;
  enterpriseAuthStatus: string;
  firstName: string;
  fullName: string;
  internationalAreaCode: string;
  isUnAuthed: boolean;
  lastLoginTime: number;
  lastName: string;
  personalAuthStatus: string;
  phone: string;
  phoneStatus: number;
  realNameAuthStatus: string;
  regIp: string;
  regTime: number;
  regWay: number;
  roleId: number;
  temporaryAuth: boolean;
  userClassify: string;
  userId: number;
  userPower: string;
  userStatus: number;
};

export type AccountType = {
  accountStatus: string;
  balance: number;
  countCoupon: number;
  createTime: string;
  creditQuota: number;
  debtStatus: string;
  debtTime: string;
  domainId: number;
  email: string;
  giveAmount: number;
  nextPaymentDay: string;
  nextRepaymentQuota: number;
  overDueFreeze: string;
  overdraftStatus: string;
  overdraftThreshold: number;
  phone: string;
  priorityBalance: number;
  repaymentQuota: number;
  sumCreditArrears: number;
  sumPaidAmount: number;
  sumUsedCredit: number;
  usedCredit: number;
  userId: number;
  warnStatus: string;
  warnThreshold: number;
  reallyBalance: number;
  rechargeMomey: number;
};

export interface UserModelState {
  user?: UserType; // 当前用户信息
  account?: AccountType; // 获取当前账户信息
}

export interface UserModelType {
  namespace: 'user';
  state: UserModelState;
  effects: {
    fetchUserInfo: Effect;
    fetchAccountInfo: Effect;
  };
  reducers: {
    saveUserInfo: Reducer<UserModelState>;
    saveAccountInfo: Reducer<UserModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<UserModelState>;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',
  state: {
    user: undefined,
    account: undefined,
  },

  effects: {
    *fetchUserInfo({ payload }, { call, put }) {
      const res = yield call(fetchUserInfo, payload);
      if (res.success) {
        yield put({
          type: 'saveUserInfo',
          payload: res.data,
        });
      } else {
        history.push('/user/login');
      }
    },
    *fetchAccountInfo({ payload }, { call, put }) {
      const res = yield call(fetchAccountInfo, payload);
      if (res.success) {
        yield put({
          type: 'saveAccountInfo',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveUserInfo(state, action) {
      return {
        ...state,
        user: { ...action.payload },
      };
    },
    saveAccountInfo(state, action) {
      return {
        ...state,
        account: { ...action.payload },
      };
    },
  },
};

export default UserModel;
